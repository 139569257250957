/* eslint-disable @typescript-eslint/no-empty-function */
import { GlobalHeaderProps } from '@mono/brand';
import { FeatureFlag } from '@mono/data/feature-flags';
import {
  BasicExperimentVariant,
  Experiment,
  useOptimizelyFeature,
  useOptimizelyHasExperimentVariant,
} from '@mono/data/optimizely';
import { UserResponseData } from '@mono/data/user';
import { getSearchPath } from '@mono/util/url';
import { useRouter } from 'next/router';

import { trackEvent, trackUserClick } from '~/libs/tracking';

import { useNormalizedUserProps } from './useNormalizedUserProps';

export const useHeaderProps = (
  pwaMode: boolean,
  user: UserResponseData,
  simpleHeader: boolean
): GlobalHeaderProps => {
  const router = useRouter();
  const normalizedUser = useNormalizedUserProps(user);
  const isInMoneyBackGuaranteeVariant = useOptimizelyHasExperimentVariant(
    Experiment.MONEYBACK_GUARANTEE,
    BasicExperimentVariant.VARIANT
  );

  const siteSearchUpgrade = useOptimizelyFeature(
    FeatureFlag.SITE_SEARCH_UPGRADE
  );

  const teamsRevampEnabled = useOptimizelyFeature(
    FeatureFlag.CYCLE_E_TEAMS_REVAMP
  );

  const unifiedRecFlowV2Enabled = useOptimizelyFeature(
    FeatureFlag.CYCLE_E_UNIFIED_REC_FLOW_V2
  );

  if (simpleHeader) {
    return {
      action: (_event, item) => {
        if (item.type !== 'render-element') {
          trackUserClick({
            context: 'global_nav',
            target: item.trackingTarget,
          });
        }
      },
      search: {
        onEnable: () => {},
        onSearch: () => {},
        onTrackingClick: () => {},
        onSearchAsYouType: () => {},
      },
      type: 'simple',
    };
  }

  return {
    action: (_event, item) => {
      if (item.type !== 'render-element') {
        trackUserClick({
          context: 'global_nav',
          target: item.trackingTarget,
        });
      }
    },
    hidePricing: pwaMode,
    onLinkAction: (event, item) => {
      // don't use router.push for path links or dashboard link in header until they're fully migrated to portal app
      if (
        item.href === '/' ||
        item.href.includes('/learn/paths') ||
        item.href.includes('/learn')
      )
        return;

      event.preventDefault();
      return router.push(item.href);
    },
    search: {
      onEnable: () => {
        trackUserClick({
          context: 'global_nav',
          target: 'topnav_search',
        });
      },
      onSearch: (query, fromPrevSearch) => {
        return router.push(getSearchPath(query, fromPrevSearch));
      },
      onTrackingClick: (target, extraTrackingProps) => {
        trackUserClick({
          context: 'searchbar',
          target,
          ...(extraTrackingProps ?? {}),
        });
      },
      onSearchAsYouType: (
        query: string,
        searchId: string,
        resultsCount: number,
        queryLoadTime: number
      ) => {
        trackEvent('search', 'query', {
          search_id: searchId,
          search_term: query,
          total_results: resultsCount,
          codecademy_env: process.env.NEXT_PUBLIC_ENV,
          page_name: 'platform',
          query_load_time: queryLoadTime,
          misc: JSON.stringify({ type: 'search-as-you-type' }),
        });
      },
    },
    isInMoneyBackGuaranteeVariant,
    ...normalizedUser,
    siteSearchUpgrade,
    teamsRevampEnabled,
    unifiedRecFlowV2Enabled,
  };
};
