export const slugOrBlank = (slug: string | undefined) =>
  slug ? `/${slug}` : '';

export const getAssetPath = (path: string) =>
  `https://static-assets.codecademy.com/assets/${path}`;

export const getCatalogBasePath = () => '/catalog';

export const getCatalogPath = ({
  categoryType,
  category,
}: {
  categoryType: string;
  category?: string;
}) => `${getCatalogBasePath()}/${categoryType}${slugOrBlank(category)}`;

export const getLearnBasePath = () => '/learn';

export const getLearnPath = ({
  slug,
  isPath,
  isExtCourse,
  isExtPath,
}: {
  slug: string;
  isPath?: boolean;
  isExtCourse?: boolean;
  isExtPath?: boolean;
}) =>
  `${getLearnBasePath()}/${
    isPath
      ? 'paths/'
      : isExtCourse
      ? 'ext-courses/'
      : isExtPath
      ? 'ext-paths/'
      : ''
  }${slug}`;

export const getEnrolledLearnPath = ({
  slug,
  isPath,
}: {
  slug: string;
  isPath?: boolean;
}) => `/enrolled/${isPath ? 'paths/' : 'courses/'}${slug}`;

export const getPathImageUrl = (pathSlug: string) =>
  `https://static-assets.codecademy.com/components/curriculum/path/${pathSlug}/curriculum-card.svg`;

export const getLearnerStoriesPath = () =>
  '/resources/blog/category/learner-stories/';

export const getExternalCoursePath = (slug: string) => `/ext-courses/${slug}`;

export const getExternalPathPath = (slug: string) => `/ext-paths/${slug}`;

export const getSmartPracticePath = ({
  slug,
  contentType,
  source = 'syllabus',
}: {
  slug: string;
  contentType: string;
  source?: string;
}) => `/smart-practice/${contentType}s/${slug}?source=${source}`;

export const getJobReadinessPath = () => '/job-readiness';
